import React from 'react'
import logo from '../../images/healfy-logo-white.svg'
import AnchorLink from 'react-anchor-link-smooth-scroll'

export const Header = () => (
  <header>
    <div className="container">
      <div className="d-flex justify-content-between py-3">
        <div className="header__logo">
          <img src={logo} alt="logo" />
        </div>
        <ul className="d-flex flex-row px-2">
          <li>
            <AnchorLink href="#hur-funkar-det">Så funkar HEALFY</AnchorLink>
          </li>
          <li>
            <AnchorLink href="#om-oss">Om oss</AnchorLink>
          </li>
          <li>
            <a href="https://minasidor.healfy.se">Logga in</a>
          </li>
        </ul>
      </div>
    </div>
  </header>
)
